
import { createApp, markRaw } from 'vue';
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router';
import { routes } from './routes.js';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import craftSectionData from '../shared/plugins/CraftSectionData.js';
import craftGraphqlApiClient from '../shared/plugins/CraftGraphqlApiClient.js';
import craftActionApiClient from '../shared/plugins/CraftActionApiClient.js';
import messages from '../manage/plugins/Messages.js';

const root = document.getElementById( 'app-management-area' );
// Might not be on a IoM Management Area page.
if ( root ) {
	const router = createRouter( {
		history: createWebHistory( '/manage' ),
		routes,
	} );

	const app = createApp( App );

	app.use( craftSectionData, JSON.parse( root.dataset.craftSectionData ) );
	app.use( craftGraphqlApiClient, {
		base_url: '/api',
	} );
	app.use( craftActionApiClient );

	app.use( messages );

	//app.config.globalProperties.$minMaxOpeningHours = JSON.parse(
	//	root.dataset.minMaxOpeningHours
	//);
	app.config.globalProperties.$bloomsburyEntryId = parseInt(
		root.dataset.bloomsburyEntryId,
		10
	);

	const pinia = createPinia();
	pinia.use( piniaPluginPersistedstate );
	pinia.use( ( context ) => {
		context.store.craftGraphqlApiClient = markRaw(
			context.app.config.globalProperties.$craftGraphqlApiClient
		);
		//context.store.craftActionApiClient = markRaw(
		//	context.app.config.globalProperties.$craftActionApiClient
		//);
	} );

	app.use( router );
	app.use( pinia );

	app.mount( root );
}
