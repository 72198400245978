
import { h } from 'vue';
import { RouterView } from 'vue-router';

import AllActivitiesView from 'views/bookings/AllActivitiesView.vue';
import EditActivityView from 'views/bookings/EditActivityView.vue';

import AllToolBookingsView from 'views/bookings/AllToolBookingsView.vue';
import EditToolBookingView from 'views/bookings/EditToolBookingView.vue';

import AllMembersView from 'views/members/AllMembersView.vue';
import EditMemberView from 'views/members/EditMemberView.vue';

import AllToolsView from 'views/tools/AllToolsView.vue';
import AllToolClassesView from 'views/tools/AllToolClassesView.vue';
import EditToolView from 'views/tools/EditToolView.vue';
import EditToolClassView from 'views/tools/EditToolClassView.vue';

import AllTasksView from 'views/tasks/AllTasksView.vue';
import ManageTasksView from 'views/tasks/ManageTasksView.vue';
import EditTaskView from 'views/tasks/EditTaskView.vue';
import AllReportsView from 'views/tasks/AllReportsView.vue';
import EditReportView from 'views/tasks/EditReportView.vue';

import AllAnnouncementsView from 'views/announcements/AllAnnouncementsView.vue';
import EditAnnouncementsView from 'views/announcements/EditAnnouncementsView.vue';

// If you add a route here, make sure you add it to the appropriate child_routes
// parameter in SidebarComponent.vue else nav highlighting won't work properly.
// Also add it to routes.php so you don't get 404s.
export const routes = [
	{
		path: '/',
		redirect: '/bookings/activities',
	},
	{
		path: '/bookings/activities',
		component: { render: () => h( RouterView ) },
		children: [
			{
				path: '',
				component: AllActivitiesView,
				name: 'bookings__all_activities',
			},
			{
				path: '/bookings/activities/add',
				component: EditActivityView,
				name: 'bookings__add_activity',
			},
			{
				path: '/bookings/activities/edit/:id',
				component: EditActivityView,
				name: 'bookings__edit_activity',
			},
			{
				path: '/bookings/activities/duplicate/:id',
				component: EditActivityView,
				name: 'bookings__duplicate_activity',
			},
		],
	},
	{
		path: '/bookings/tools',
		component: { render: () => h( RouterView ) },
		children: [
			{
				path: '',
				component: AllToolBookingsView,
				name: 'bookings__all_tool_bookings',
			},
			{
				path: '/bookings/tools/add',
				component: EditToolBookingView,
				name: 'bookings__add_tool_booking',
			},
			{
				path: '/bookings/tools/edit/:id',
				component: EditToolBookingView,
				name: 'bookings__edit_tool_booking',
			},
			{
				path: '/bookings/tools/duplicate/:id',
				component: EditToolBookingView,
				name: 'bookings__duplicate_tool_booking',
			},
		],
	},
	{
		path: '/members',
		component: { render: () => h( RouterView ) },
		children: [
			{
				path: '',
				component: AllMembersView,
				name: 'members__all_members',
			},
			{
				path: '/members/add',
				component: EditMemberView,
				name: 'members__add_member',
			},
			{
				path: '/members/edit/:id',
				component: EditMemberView,
				name: 'members__edit_member',
			},
		],
	},
	{
		path: '/tools',
		component: { render: () => h( RouterView ) },
		children: [
			{
				path: '',
				component: AllToolsView,
				name: 'tools__all_tools',
			},
			{
				path: '/tools/add',
				component: EditToolView,
				name: 'tools__add_tool',
			},
			{
				path: '/tools/edit/:id',
				component: EditToolView,
				name: 'tools__edit_tool',
			},
			{
				path: '/tools/duplicate/:id',
				component: EditToolView,
				name: 'tools__duplicate_tool',
			},
		],
	},
	{
		path: '/tools/tool-classes',
		component: { render: () => h( RouterView ) },
		children: [
			{
				path: '',
				component: AllToolClassesView,
				name: 'tools__all_tool_classes',
			},
			{
				path: '/tools/tool-classes/add',
				component: EditToolClassView,
				name: 'tools__add_tool_class',
			},
			{
				path: '/tools/tool-classes/edit/:id',
				component: EditToolClassView,
				name: 'tools__edit_tool_class',
			},
			{
				path: '/tools/tool-classes/duplicate/:id',
				component: EditToolClassView,
				name: 'tools__duplicate_tool_class',
			},
		],
	},
	{
		path: '/tasks/schedule',
		component: { render: () => h( RouterView ) },
		children: [
			{
				path: '',
				component: AllTasksView,
				name: 'tasks__all_tasks',
			},
		],
	},
	{
		path: '/tasks',
		component: { render: () => h( RouterView ) },
		children: [
			{
				path: '',
				component: ManageTasksView,
				name: 'tasks__manage_tasks',
			},
			{
				path: '/tasks/add',
				component: EditTaskView,
				name: 'tasks__add_task',
			},
			{
				path: '/tasks/edit/:id',
				component: EditTaskView,
				name: 'tasks__edit_task',
			},
			{
				path: '/tasks/duplicate/:id',
				component: EditTaskView,
				name: 'tasks__duplicate_task',
			},
		],
	},
	{
		path: '/reports',
		component: { render: () => h( RouterView ) },
		children: [
			{
				path: '',
				component: AllReportsView,
				name: 'reports__all_reports',
			},
			{
				path: '/reports/add',
				component: EditReportView,
				name: 'reports__add_report',
			},
			{
				path: '/reports/edit/:id',
				component: EditReportView,
				name: 'reports__edit_report',
			},
		],
	},
	{
		path: '/announcements',
		component: { render: () => h( RouterView ) },
		children: [
			{
				path: '',
				component: AllAnnouncementsView,
				name: 'announcements__all_announcements',
			},
			{
				path: '/announcements/add',
				component: EditAnnouncementsView,
				name: 'announcements__add_announcement',
			},
			{
				path: '/announcements/edit/:id',
				component: EditAnnouncementsView,
				name: 'announcements__edit_announcement',
			},
			{
				path: '/announcements/duplicate/:id',
				component: EditAnnouncementsView,
				name: 'announcements__duplicate_announcement',
			},
		],
	},
];
